import { getCommonReportInfo, } from '@/core/reportInfo';
import { LogCategory, LogLevel, } from '@/config/const';
import { report, } from '@/utils/http';

export default function traceResourceError() {
    window.addEventListener('error', function(e) {
        let eventType = [].toString.call(e, e);
        // 过滤掉运行时错误
        if (eventType === '[object Event]') {
            // 上报加载错误
            let errJson = {};
            if (e.type === 'error') {
                let target = e.target;
                errJson.el = (target.nodeName).toLowerCase();
                if (errJson.el === 'img') {
                    errJson.msg = '图片加载异常';
                    errJson.src = target.src;
                    if (target.id) {
                        errJson.id = target.id;
                    }

                    if (target.className) {
                        errJson.classes = target.className;
                    }
                }

                if (errJson.el === 'script') {
                    errJson.msg = '脚本文件加载异常';
                    errJson.src = target.src;
                }

                if (errJson.el === 'link') {
                    errJson.msg = '样式表加载异常';
                    errJson.href = target.href;
                }
                errJson.html = target.outerHTML;
                let params = getCommonReportInfo(LogCategory.RESOURCE_ERROR, LogLevel.WARN, errJson);
                params.message = errJson.msg;
                params.url = target.src || errJson.href;
                report(params);
            }
        }
    }, true);
}