/*
 * @Author: 陈建中
 * @Date: 2022-04-25 15:56:26
 * @LastEditTime: 2022-05-31 17:51:06
 * @LastEditors: 陈建中
 * @FilePath: /nextop-monitor/src/erros/clickEvent.js
 */
import { reportCustom, } from '@/utils/http';
import { LogLevel, LogCategory, } from '@/config/const';
import { getCommonReportInfo, } from '@/core/reportInfo';

export default function traceClickEvent() {
    window.addEventListener('click', function(event) {
        console.log('window.addEventListener.click', event);
        reportClickEvent(event);
    }, true);
}

export function reportClickEvent(event, data = {}) {
    if (event.target.tagName === 'HTML') return;
    let info = {
        url: document.location.href,
        pageX: event.pageX,
        pageY: event.pageY,
        el: event.target.outerHTML,
        tagName: event.target.tagName,
    };
    let params = getCommonReportInfo(LogCategory.CUSTOM_EVENT, LogLevel.INFO, info);
    params.url = event.target.outerHTML;
    params.message = event.target.innerHTML;
    params = Object.assign({}, params, data);
    reportCustom(data.key, params);
}
