/*
 * @Author: 陈建中
 * @Date: 2022-04-25 15:56:26
 * @LastEditTime: 2024-04-11 16:37:53
 * @LastEditors: guoang
 * @FilePath: /nextop-monitor/src/core/reportInfo.js
 */
import { config, } from '@/config';
import { LogCategory, LogLevel, } from '@/config/const';

/**
 * 上报信息的基础参数
 * @param {*} category 上报信息记录的分类
 * @param {*} level 上报信息记录的级别
 * @returns
 */
export function getCommonReportInfo (category = LogCategory.UNKNOW_ERROR, level = LogLevel.INFO, errorObj = '') {
    let n = window.navigator;
    let { getUserId, getTenantId, getDefaultTenantName, getUserName, appKey, } = config;
    return {
        // 基础信息
        category: category,
        level: level,
        project: config.project, // 项目
        // logTime: (new Date()).getTime(),
        page: window.location.href,
        url: '',
        // 用户信息
        userId: getUserId && getUserId(),
        userName: getUserName && getUserName(),
        tenantId: getTenantId && getTenantId(),
        tenantName: getDefaultTenantName && getDefaultTenantName(),
        // 设备信息
        userAgent: n.userAgent,
        // 错误信息
        message: '',
        // 应用key
        appKey,
        logInfo: JSON.stringify(errorObj),
        ...config.extendsInfo,
    };
}
