/**
 * 转成对象为url参数
 * @param {*} data 参数对象
 * @returns 参数字符串
 */
export function formatParams(data) {
    let arr = [];
    for (let name in data) {
        arr.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
    }
    return arr.join('&');
}

/**
 * 判断是否是url
 * @param {*} url
 * @returns
 */
export function isURL(url) {
    if (!url){
        return false;
    }
    let urlRule =/^[hH][tT][tT][pP]([sS]?):\/\//;
    return urlRule.test(url);
}