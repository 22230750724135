import { report, } from '@/utils/http';
import { LogLevel, LogCategory, } from '@/config/const';
import { getCommonReportInfo, } from '@/core/reportInfo';

export default function traceJsError() {
    window.onerror = (msg, url, line, col, error) => {
        try {
            let info = {
                url: url,
                message: msg,
                line: line,
                col: col,
                stack: error && error.stack,
            };
            let params = getCommonReportInfo(LogCategory.JS_ERROR, LogLevel.ERROR, info);
            params.url = url;
            params.message = msg;
            report(params);
            // return true; // 返回 true 的时候，异常不会向上抛出，控制台不会输出错误
        } catch {
            console.log('js错误异常', error);
        }
    };
}