/*
 * @Author: 陈建中
 * @Date: 2022-05-24 14:05:52
 * @LastEditTime: 2022-06-01 11:52:30
 * @LastEditors: 陈建中
 * @FilePath: /nextop-monitor/src/utils/http.js
 */
import { config, } from '@/config';
import { formatParams, isURL, } from '@/utils/tools';

/**
 * axjx上报信息
 * @param {*} data 上报参数
 * @returns
 */
export function report(data) {
    if (!isURL(config.url)) {
        console.log(`[nextop-monitor]上报信息url地址格式不正确,url=${config.url}`);
        return;
    }
    post(data);
}

/**
 * axjx上报信息-自定义事件上报
 * @param {String} key 事件key
 * @param {Object} data 上报参数
 * @returns
 */
export function reportCustom(key, data) {
    if (!isURL(config.url)) {
        console.log(`[nextop-monitor]上报信息url地址格式不正确,url=${config.url}`);
        return;
    }
    post({
        ...data,
        key,
    });
}

/**
 * 通过img上报信息
 * @param {*} data 上报参数
 * @returns
 */
export function reportByImg(data) {
    try {
        let img = new Image();
        img.src = config.url+'?' + formatParams(data);
        img.crossOrigin = 'Anonymous';
    } catch (error) {
        console.log(error);
    }
}

/**
 * post请求
 * @param {*} data 参数
 */
export function post(data) {
    let dataStr = JSON.stringify(data);
    try {
        let xhr = new XMLHttpRequest();
        xhr.open('POST', config.url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(dataStr);
    } catch (error) {
        console.log(error);
    }
}
