import { report, } from '@/utils/http';
import { LogLevel, LogCategory, } from '@/config/const';
import { getCommonReportInfo, } from '@/core/reportInfo';
import { config, } from '@/config';

const EXCLUDE_REPORT_CODES = [0, 200, 204, 304];

export default function traceAjaxError() {
    let tempOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function(method, url, async, user, pass) {
        let startTime = 0,
            endTime = 0;
        this.addEventListener('readystatechange', function(evt) {
            if (url === config.url) {
                return;
            }
            if (this.readyState === 1) {
                startTime = evt.timeStamp;
            }
            if (this.readyState === 4) {
                if (!EXCLUDE_REPORT_CODES.includes(this.status)) {
                    endTime = evt.timeStamp;
                    let info = {
                        method: method,
                        url: url,
                        async: async,
                        status: evt.target.status || 404,
                        response: evt.target.response,
                        time: `${(endTime - startTime).toFixed(3)}ms`,
                    };
                    let params = getCommonReportInfo(LogCategory.AJAX_ERROR, LogLevel.WARN, info);
                    params.message = 'AJAX 请求错误';
                    params.url = url;
                    // 把错误信息发送给后台
                    report(params);
                }
            }
        }, false);
        tempOpen.call(this, method, url, async, user, pass);
    };
}