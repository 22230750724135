import { report, } from '@/utils/http';
import { LogLevel, LogCategory, } from '@/config/const';
import { getCommonReportInfo, } from '@/core/reportInfo';

export default function traceFetchError() {
    if (!window.fetch) return;
    let _oldFetch = window.fetch;
    window.fetch = function() {
        return _oldFetch
            .apply(this, arguments)
            .then((res) => {
                if (!res.ok) {
                    let params = getCommonReportInfo(LogCategory.FETCH_ERROR, LogLevel.ERROR, {
                        status: res.status,
                        statusText: res.statusText,
                        url: res.url,
                    });
                    params.message = 'Fetch 请求错误';
                    params.url = res.url;
                    report(params);
                }
                return res;
            })
            .catch((error) => {
                let params = getCommonReportInfo(LogCategory.FETCH_ERROR, LogLevel.ERROR, { error: error.toString(), });
                params.message = 'Fetch 请求错误';
                params.url = arguments[0];
                report(params);
            });
    };
}
