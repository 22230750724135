/*
 * @Author: 陈建中
 * @Date: 2022-04-25 15:56:26
 * @LastEditTime: 2024-04-11 16:36:40
 * @LastEditors: guoang
 * @FilePath: /nextop-monitor/src/config/index.js
 */
export let config = {
    url: 'https://webmonitor-server.nextop.com/api/v1/report',
    project: document.title,           // 监控的项目
    appKey: null,           // 应用KEY 必填 在监控后台-应用列表
    jsError: true,          // 是否开启上报js报错
    promiseError: true,     // 是否开启上报promise报错
    resourceError: true,    // 是否开启上报资源加载错误
    ajaxError: true,        // 是否开启上报ajax请求错误
    fetchError: true,       // 是否开启上报fetch请求错误
    vueError: true,         // 是否开启上报vue错误
    clickEvent: false,       // 是否开启上报点击事件
    getUserId: function() {
        const userInfo = JSON.parse(localStorage.getItem('nextop-micro:USER_INFO')) || {};
        return userInfo.id || '';
    },
    getUserName: function() {
        const userInfo = JSON.parse(localStorage.getItem('nextop-micro:USER_INFO')) || {};
        return userInfo.name || '';
    },
    getTenantId: function() {
        const userInfo = JSON.parse(localStorage.getItem('nextop-micro:USER_INFO')) || {};
        return userInfo.defaultTenantId || '';
    },
    getDefaultTenantName: function() {
        const userInfo = JSON.parse(localStorage.getItem('nextop-micro:USER_INFO')) || {};
        return userInfo.defaultTenantName || '';
    },
    extendsInfo: {},
};

/**
 * 设置配置项
 * @param {*} options 配置
 */
export function setConfig(options) {
    config = {
        ...config,
        ...options,
    };
}

/**
 * 获取配置项信息
 * @param {*} key 标识
 * @returns 配置的值
 */
export function getConfig(key) {
    return config[key];
}
