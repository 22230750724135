import { report, } from '@/utils/http';
import { LogLevel, LogCategory, } from '@/config/const';
import { getCommonReportInfo, } from '@/core/reportInfo';

export default function tracePromiseError() {
    window.addEventListener('unhandledrejection', (event)=> {
        try {
            if (!event || !event.reason || !event.reason.stack) {
                return;
            }
            let errorObj = {
                message: event.reason.message,
                stack: event.reason.stack,
            };
            let params = getCommonReportInfo(LogCategory.PROMISE_ERROR, LogLevel.WARN, errorObj);
            if (event.reason.config && event.reason.config.url) {
                params.url = event.reason.config.url;
            }
            params.message = event.reason.message;
            report(params);
        } catch (error) {
            console.log(error);
        }
    }, true);
}
