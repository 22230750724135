/*
 * @Author: lingyong.zeng
 * @Date: 2022-05-26 09:51:54
 * @LastEditors: lingyong.zeng
 * @LastEditTime: 2022-05-26 13:47:40
 * @Description:
 * @FilePath: /nextop-monitor/src/erros/vueError.js
 */
import { getCommonReportInfo, } from '@/core/reportInfo';
import { LogCategory, LogLevel, } from '@/config/const';
import { report, } from '@/utils/http';

const EXCLUDE_ERROR_TYPES = ['AJAX_CANNCELED'];

export default function traceVueError() {
    // eslint-disable-next-line no-undef
    if (!window.Vue) {
        return;
    }
    // eslint-disable-next-line no-undef
    window.Vue.config.errorHandler = (error, vm, info) => {
        if (EXCLUDE_ERROR_TYPES.includes(error.type)) {
            return;
        }
        try {
            let metaData = {
                message: error.message,
                stack: error.stack,
                info: info,
            };
            if (Object.prototype.toString.call(vm) === '[object Object]') {
                metaData.componentName = vm._isVue ? vm.$options.name || vm.$options._componentTag : vm.name;
                metaData.propsData = vm.$options.propsData;
            }
            let params = getCommonReportInfo(LogCategory.VUE_ERROR, LogLevel.ERROR, metaData);

            params.message = error.message;

            report(params);
            console.error(error); // 不拦截报错
        } catch (error) {
            console.log('vue错误异常', error);
        }
    };
}