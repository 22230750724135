/**
 * 上报记录的分类
 */
export const LogCategory = {
    JS_ERROR: 'js_error',                   // js 错误
    RESOURCE_ERROR: 'resource_error',       // 资源引用错误
    VUE_ERROR: 'vue_error',                 // Vue错误
    PROMISE_ERROR: 'promise_error',         // promise错误
    AJAX_ERROR: 'ajax_error',               // ajax一步请求错误
    FETCH_ERROR: 'fetch_error',             // fetch请求错误
    UNKNOW_ERROR: 'unknow_error',           // 未知错误
    CLICK_EVENT: 'click_event',             // 点击事件
    CUSTOM_EVENT: 'custom_event',           // 自定义上报消息
};

/**
 * 上报记录的级别
 */
export const LogLevel = {
    ERROR: 'Error',
    WARN: 'Warning',
    INFO: 'Info',
};

