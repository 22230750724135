

import { reportClickEvent, } from '../erros/clickEvent';
export const vReport = (Vue) => {
    Vue.directive('report', {
        bind(el, binding) {
            el.addEventListener('click', (event)=>{
                reportClickEvent(event, binding.value);
            });
        },
    });
};