import { config, setConfig, } from '@/config';
import { tracePromiseError, traceAjaxError, traceJsError, traceResourceError, traceVueError, traceFetchError, traceClickEvent, } from '@/erros';
import { vReport, } from '@/directives';
import { reportCustom, } from '@/utils/http';
import { getCommonReportInfo, } from '@/core/reportInfo';
import { LogCategory, } from '@/config/const';

const NextopMonitor = {
    installed: false,
    init(options = {}) {
        if (!options.appKey) {
            console.warn('[nextop-monitor]: appKey不能为空');
            return;
        }
        setConfig(options);
        let {
            jsError,
            promiseError,
            resourceError,
            ajaxError,
            fetchError,
            vueError,
            clickEvent,
        } = config;

        jsError && traceJsError();
        resourceError && traceResourceError();
        promiseError && tracePromiseError();
        ajaxError && traceAjaxError();
        fetchError && traceFetchError();
        vueError && traceVueError();
        clickEvent && traceClickEvent();
        this.installed = true;
    },

    install(Vue, options = {}) {
        // 初始化
        if (!this.installed) {
            this.init(options);
        }

        // 添加全局资源
        vReport(Vue);

        //  添加实例方法
        Vue.prototype.$report = this.report;
    },
    report(key, data) {
        let params = getCommonReportInfo(LogCategory.CUSTOM_EVENT);
        params = Object.assign({}, params, data);
        reportCustom(key, params);
    },
};

export default NextopMonitor;